:root {
  --yellow: #fccd35;
  --pink: #ff19e9;
  --green: #00cc00;
  --white: #ffffff;
  --light-grey: #b1b1b1;
  --medium-grey: #444;
  --black: #000000;
  --teal: #316879;
  --coral: #f47a60;
  --turquoise: #7fe7dc;
  --grey: #ced7d8;
}

.warning--text{
  background-color: var(--coral);
  color: #000;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
}

html{
  scroll-behavior: smooth;
}

header{
  position: sticky;
  top: 0;
  z-index: 120;
}

section{
  scroll-snap-align: start;
}

.nav-link{
  position: relative;
  color: var(--black);
  transition: background-color .5s ease;
  font-size: 1.1rem;
  background-color: transparent;
  background-position: left;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}

.nav-link::before{
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--turquoise);
  transition: width .5s ease;
}

.nav-link:active, .nav-link:focus{
  color: var(--black);
}

.nav-link:hover{
  color: var(--black);
  text-decoration: none;
}
.nav-link:hover::before{
  width: 100%;
}

h1{
  color: var(--medium-grey);
}

p {
  margin: 1rem 0;
}

section{
  margin: 2rem 0;
}


#home{
  min-height: 75vh;
  height: 1px;
}

.home--content > *:not(:last-child){
  mix-blend-mode: exclusion;
  color: var(--white);
}

.home--content .btn-primary{
  background-color: var(--teal);
  border-color: var(--teal);

}

#concept{
  position: relative;
  min-height: 100vh;
  margin-block-start: 20px;
  margin-block-end: 0;
}

.concept--image-container{
  height: 100vh;
}

.concept--text_container{
  padding: 100px 0;
  width: 100%;
}

#concept .text--content{
  color: var(--black);
}

#concept img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  object-fit: cover;
  pointer-events: none;
  padding: 0;
}

#concept::before{
  content:'';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to top, #094b65, transparent);
  z-index: 10;
}


#concept .dinos{
  top: auto;
  width: 400px;
  height: 400px;
  bottom: 18%;
}

#concept #dino1{
  right: 0;
  left: auto;
}

#concept #dino2{
  left: 10%;
}

#concept #ForestAndWater{
  height: 100%;
}

#goal, #roadmap, #faq {
  margin-block-start: 0;
  margin-block-end: 0;
  position: relative;
  background-color: #094b65;
  padding: 100px;
}

#goal h2{
  font-size: 3.5em;
  color: var(--white);
  margin-block-end: 3rem;
  text-align: center;
}

#goal li{
  font-size: 1em;
  color: #fff;
  margin: 1rem 0;
}

.gallery{
  display: flex;
  flex-wrap: wrap;
}

.gallery > div{
  flex: 0 0 50%;
}


.gallery img{
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery img.animated--dino_static{
  position: relative;
  transition: width 0.5s ease, height 0.5s ease;
  will-change: width, height ;
  width: 0;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.gallery.active img:nth-child(1){
  transition: width 0.8s ease;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.gallery.active img:nth-child(2){
  transition-delay: 0.2s;
  transition: height 1s ease;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.gallery.active img:nth-child(3){
  transition-delay: 0.4s;
  transition: width 1.2s ease;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.gallery.active img:nth-child(4){
  transition-delay: 0.8s;
  transition: height 1.4s ease;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
}


img.animated--dino{
  position: absolute;
  width: 318px;
  height: 318px;
}

#dinoAnimated1{
  top: 0;
  left: 0;
  z-index: -1;
  transform: translate(125%, -300%);
  transform-origin: center left;
  animation: moveInLeft 1s ease;
  animation-fill-mode: forwards;
}

#dinoAnimated2{
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: translate(0, 500%);
  transform-origin: center left;
  animation: moveInRight 1s ease;
  animation-fill-mode: forwards;
}

.roadmap--details-container{
  max-width: 1080px;
  margin: 50px auto;
}

.roadmap--details-container .roadmap--details{
   display: flex;
}

.roadmap--details-container .roadmap--details > div:first-child{
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  width: calc(50% - 40px);
  padding: 20px;
}

.roadmap--details-container .roadmap--details > div:first-child::before{
  content: '';
  display: block;
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: white;
  top: 28px;
  transform: rotate(45deg);
}

.roadmap--center-line{
   position: absolute;
   top: 20px;
   height: 100%;
   width: 4px;
   background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
}

.roadmap--title{
  position: relative;
  z-index: 12;
  font-size: 18px;
  color: #000;
}

.roadmap--points{
  position: absolute;
  top: 13px;
  background-color: #f2f2f2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.roadmap--scroll-icon{
  position: absolute;
  bottom: 0;
  background-color: #f2f2f2;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  left: 50%;
  transform: translateX(-50%)
}

.roadmap--points svg,
.roadmap--scroll-icon svg{
  width: 100%;
  height: 100%;
  padding: 8px;
}
.roadmap--scroll-icon svg{
  padding: 10px;
}

.roadmap--details-container >  .roadmap--details:nth-child(1)::before{
  content: none !important;
}
.roadmap--details-container >  .roadmap--details:nth-child(even){
  justify-content: flex-start;
}
.roadmap--details-container >  .roadmap--details:nth-child(even) > div::before{
  right: -7px;
}

.roadmap--details-container >  .roadmap--details:nth-child(even) > div .roadmap--points{
  right: -60px;
}

.roadmap--details-container >  .roadmap--details:nth-child(odd){
  justify-content: flex-end;
}

.roadmap--details-container >  .roadmap--details:nth-child(odd) > div::before{
  left: -7px;
}

.roadmap--details-container >  .roadmap--details:nth-child(odd) > div .roadmap--points{
  left: -60px;
}

.list-style-none{
  list-style-type: none;
}

.team--member{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

.team--member img{
  width: 100%;
  height: 400px;
  object-fit: contain;
  margin-block-end: 16px;
}

#team::before{
  content:'';
  display: block;
  position: absolute;
  bottom: -32px;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to top, #094b65, transparent);
  z-index: 10;
}

#team::after{
  content:'';
  display: block;
  position: absolute;
  top: -32px;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(to bottom, #094b65, transparent);
  z-index: 10;
}

#team h2{
  font-size: 5rem;
  letter-spacing: 8px;
}

.navbar-toggler:focus{
  outline: 0 !important;
  box-shadow: none !important;
}

.modal--container{
  position: fixed;
  top: 20px;
  left: 50%;
  width: 500px;
  max-width: 100%;
  text-align: center;
  height: 50px;
  line-height: 50px;
  transform: translateX(-50%);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  z-index: 250;
  overflow: hidden;
  animation: scale 1s ease forwards;
  transform-origin: center;
  transform: scale(0) translateX(-50%);
}

.modal--container::before{
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
}

.modal--container.modal--error::before{
  background-color: #dc3545;
}

.modal--container.modal--success::before{
  background-color: #198754;
}

@keyframes scale {
  0%{
    transform: scale(0) translateX(-50%);
  }

  100% {
    transform: scale(1) translateX(-50%);
  }
}

@keyframes moveInLeft{
  0%{
    transform: translate(-200%, -10%);
  }

  100%{
    transform: translate(125%, -5%) rotateZ(-30deg);
  }
}

@keyframes moveInRight{
  0%{
    transform: translate(0, 500%);
  }

  100%{
    transform: translate(0, 0) rotateZ(20deg);
  }
}


@media screen and (min-width: 1921px){
  #concept #forest{
    object-position: 0 100%;
  }
}

@media screen and (max-width: 1920px){
  .custom--ratio{
    padding-top: 37%;
  }
}

@media screen and (max-width: 1200px){
  #concept #ForestAndWater{
    object-position: right;
  }
  #concept #dino2{
    left: 0;
  }
  .animated--dino{
    display: none;
  }
  .home--content{
    text-align: center;
  }
  .gallery img.animated--dino_static{
    position: static;
    width: 100%;
    height: 100%;
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover;
  }
  #home{
    min-height: auto;
    height: auto;
  }
}

@media screen and (max-width: 992px) {
  #concept #ForestAndWater{
    object-position: center;
  }
  #concept .dinos{
    width: 350px;
    height: 350px;
  }
 
  .roadmap--center-line{
    left: 20px;
  }
  .roadmap--details-container .roadmap--details > div:first-child{
    width: 100%;
  }
  .roadmap--details-container >  .roadmap--details:nth-child(even) > div::before{
    left: -7px;
  }
  .roadmap--details-container >  .roadmap--details:nth-child(even) > div .roadmap--points{
    left: -60px;
  }
  .navbar-collapse{
    padding: 24px 0;
    border-radius: 5px;
    background-color: #fff;
  }
  .nav-link{
    padding-inline-end: 24px !important;
    padding-inline-start: 24px !important;
    transition: color .2s ease;
    overflow: hidden;
  }
  .nav-link::before{
    top: 0;
    transform: translateY(0);
    height: 100%;
  }

  .nav-link:hover::before{
    transform: translateX(20%);
  }
  header.bg-light.shadow{
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .navbar-toggler{
    background-color: #fff;
  }
  .navbar-collapse.show{
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
  }

}

@media screen and (max-width: 772px){
  #concept{
    min-height: 50vh;
  }
  .concept--image-container{
    height: 50vh;
  }
  #concept .dinos{
    width: 200px;
    height: 200px;
  }
  #mountain{
    object-position: -140px -230px;
  }
  .concept--text_container{
    padding: 40px;
  }
  #goal h2{
    font-size: 2.5em;
  }
  .gallery{
    grid-template-columns: 1fr;
  }
  .nav-link:hover::before{
    transform: translateX(30%);
  }
}

@media screen and (max-width: 576px) {
  .roadmap--center-line .roadmap--scroll-icon,
  .roadmap--details-container >  .roadmap--details > div::before,
  .roadmap--details-container >  .roadmap--details > div .roadmap--points
  {
    display: none !important;
  }

  .roadmap--center-line{
    top: 0;
    left: 50%; 
  }
 
  .roadmap--details-container .roadmap--details{
    margin: 30px 0 3px 0;
  }

  #goal, #roadmap{
    padding: 50px 20px;
  }
  #concept .dinos{
    width: 150px;
    height: 150px;
  }
  .concept--image-container{
    height: 30vh;
  }
  .claiming-buttons-container{
    flex-direction: column;
  }
  .claiming-buttons-container button {
    margin-inline-end: 0 !important;
    margin-block-end: 16px;
  }
}